import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import Table from 'app/components/mui/Table';

const columns = [
  { label: 'ID', name: 'id', valueType: 'string', notLink: true },
  { label: 'Due date', name: 'dueDate', valueType: 'date' },
  { label: 'Amount', name: 'amount', valueType: 'number' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'Created', name: 'createdAt', valueType: 'date' },
  { label: 'Updated', name: 'updatedAt', valueType: 'date' }
];

export default class SubscriptionStatRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      if(!this.props.id) {
          return;
      }
      this.setState({ report: null, loading: true });
      // const result = await apiClient.post('/api/microservice', { data: { action: 'SubscriptionDetail', data: { id: this.props.id } } });
      this.setState({ result: null, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  render() {
    const { loading, result } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (<tr><td colSpan="12"><Table
      columns={columns}
      limitRow={25}
      rows={result?.data || []}
    /></td></tr>);
  }
}