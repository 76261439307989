import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import THead from './THead';
import TBody from './TBody';
import { Title } from '../../Title';
import Placement from '../../Placement';
import { GraphQLTableContainer } from 'app/common/table';

type TableProps = {
  size?: 'small';
  stickyHeader?: boolean;
};

import { Row, RowAction, Action, Column } from './types';

type Props = {
  rowActions?: RowAction[];
  limitRow?: number;
  columns: Column[];
  rows: Row[];
  title?: string;
  dimmedRows?: string[];
  tableProps?: TableProps;
  filterable?: boolean;
  createNew?: JSX.Element;
  url?: string;
  persistState?: boolean;
  actions?: Action[];
  sortable?: boolean;
  hideable?: boolean;
  divStyling?: any;
  paperStyling?: any;
  detailOnClick?: (row: any) => JSX.Element;
};

class TableView extends GraphQLTableContainer<Props> {
  constructor(props: Props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  updateFilter(name, value) {
    const filter = {
      ...this.state.filter,
      [name]: value
    };

    this.setState({ filter }, () => {
      this.setState({ ...this.filterRows() });
    });
  }

  handleFilterChange = (ev) => {
    const { name, value } = ev.target;
    if (name === 'name') {
      const pattern = new RegExp(value, 'i');
      const filterFn = (row) => {
        if (pattern.test(row.name)) {
          return true;
        }
        if (row.contacts.find((contact) => pattern.test(contact))) {
          return true;
        }
        return false;
      };
      return this.updateFilter(name, filterFn);
    }
  };

  render() {
    return (
      <Paper style={this.props.paperStyling || {}}>
        <div style={this.props.divStyling || {}}>
          <Placement place={'space-between'}>
            {this.props.title && <Title
              addPadding
              text={this.props.title}
              variant="h6"
              component="p"
            />}
            {this.props.actions && (
              <span>
                {this.props.actions.map((action) => {
                  const { component: Component, name, ...rest } = action;
                  return <Component key={name} {...rest} />;
                })}
              </span>
            )}
            <div style={{ textAlign: 'right' }}>
              {this.props.load && this.props.load}
              {this.props.createNew && this.props.createNew}
            </div>
          </Placement>
          <div style={this.props.scrollable ? { maxHeight: this.props.maxHeight || '250px', overflow: 'scroll' } : {}}>
            <Table
              style={{ marginBottom: '1.3em' }}
              size="small"
              {...this.props.tableProps}
            >
              <THead
                filters={this.state.filter}
                rows={this.props.columns}
                onFilterChange={this.handleFilterChange}
                onSortReq={this.sortBy}
              />
              <TBody
                url={this.props.url}
                rows={this.state.rows}
                limitRow={this.props.limitRow}
                hideable={this.props.hideable}
                keys={this.props.columns
                  .map((column) => ({
                    name: column.name,
                    link: column.link ?? null,
                    url: column.url ?? null,
                    exactLink: column.exactLink ?? null,
                    redirectLink: column.redirectLink ?? null,
                    notLink: column.notLink ?? null,
                    valueType: column.valueType,
                    customValue: column.customValue ?? null,
                    simpleLinkText: column.simpleLinkText ?? null,
                    serialize: column.serialize ?? null,
                    key: column.key ?? null
                  }))
                  .filter((c) => Boolean(c.name))}
                dimmedRows={this.props.dimmedRows}
                actions={this.props.rowActions}
                detailOnClick={this.props.detailOnClick}
              />
            </Table>
          </div>
        </div>
      </Paper>
    );
  }
}

export default TableView;
