import React from 'react';
import apiClient from 'app/utils/api-client';
import Form from 'app/common/Form';
import FormInput from 'app/common/FormInput';
import { Paper } from '@material-ui/core';
import { Title } from 'app/components/Title';
import ReactHtmlParser from 'react-html-parser';
import { AuditDetail } from './auditDetail';
import Table from 'app/components/mui/Table';

type Props = {
    data?: {
        id: string,
        name: string,
        texts: string[],
        links: { key: string, text: string, url: string }[],
        mainLink: string,
        facebookPages: string[],
        postInterval: number,
        hourFrom: number,
        hourTo: number,
        allFacebookPages: boolean,
        active: boolean,
        audits: { type: string, update?: any, facebookPageId?: string, facebookPostId?: string, timestamp: Date }[]
    },
    create?: boolean,
    onSubmit?: () => void
};

const postColumn = [
    { label: 'Facebook Page ID', name: 'facebookPageId', valueType: 'string', redirectLink: 'https://www.facebook.com' },
    { label: 'Facebook Post ID', name: 'facebookPostId', valueType: 'string', redirectLink: 'https://www.facebook.com' },
    { label: 'Timestamp', name: 'timestamp', valueType: 'date' }
  ];

const auditColumn = [
    { label: 'Type', name: 'type', valueType: 'string' },
    { label: 'Data', name: 'update', valueType: 'keys' },
    { label: 'Timestamp', name: 'timestamp', valueType: 'date' }
  ];


function HtmlContent({ htmlString }) {
  const transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'button') {
      return <button key={index}>{node.children[0].data}</button>;
    }
  };

  return <div>{ReactHtmlParser(htmlString, { transform })}</div>;
}

function toOption(opt) {
    return typeof opt === 'string' ? (
      <option value={!opt ? 'ENUM_' : opt.toUpperCase()}>{opt}</option>
    ) : (
      <option value={opt.value}>{opt.label}</option>
    );
  }
  
export const Detail = (props: Props): JSX.Element => {
    const [id, setID] = React.useState(props.data?.id);
    const [name, setName] = React.useState(props.data?.name);
    const [texts, setTexts] = React.useState(props.data?.texts || []);
    const [links, setLinks] = React.useState((props.data?.links || []).map((v) => `${v.key},${v.text},${v.url};`).join('\n'));
    const [mainLink, setMainLink] = React.useState(props.data?.mainLink || '');
    const [facebookPages, setFacebookPages] = React.useState((props.data?.facebookPages || []).join(','));
    const [postInterval, setPostInterval] = React.useState(props.data?.postInterval);
    const [hourFrom, setHourFrom] = React.useState(props.data?.hourFrom !== null ? props.data.hourFrom : '');
    const [hourTo, setHourTo] = React.useState(props.data?.hourTo !== null ? props.data.hourTo : '');
    const [allFacebookPages, setAllFacebookPages] = React.useState(props.data?.allFacebookPages);
    const [active, setActive] = React.useState(props.data?.active);
    const [audits, setAudits] = React.useState(props.data?.audits || [{ type: 'fbpost', facebookPageId: 'xxxxx', facebookPostId: 'xxxxx', timestamp: new Date() }]);

    const [numOfTexts, setNumOfTexts] = React.useState(props.data?.texts?.length || 1);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const [text1, setText1] = React.useState('');
    const [text2, setText2] = React.useState('');
    const [text3, setText3] = React.useState('');
    const [text4, setText4] = React.useState('');
    const [text5, setText5] = React.useState('');
    const [text6, setText6] = React.useState('');
    const [text7, setText7] = React.useState('');
    const [text8, setText8] = React.useState('');
    const [text9, setText9] = React.useState('');
    const [text10, setText10] = React.useState('');

    const [generatedText, setGeneratedText] = React.useState('');

    React.useEffect(() => {
        setText1(props.data?.texts[0] || '');
        setText2(props.data?.texts[1] || '');
        setText3(props.data?.texts[2] || '');
        setText4(props.data?.texts[3] || '');
        setText5(props.data?.texts[4] || '');
        setText6(props.data?.texts[5] || '');
        setText7(props.data?.texts[6] || '');
        setText8(props.data?.texts[7] || '');
        setText9(props.data?.texts[8] || '');
        setText10(props.data?.texts[9] || '');
    }, []);

    const generateText = async () => {
        const result = await apiClient.get('/api/r6/auto_posts/' + id + '/generate');
        setGeneratedText(result.result);
    };

    const generateHtml = async () => {
        const result = await apiClient.get('/api/r6/auto_posts/' + id + '/generateHtml');
        setGeneratedText(result.result);
    };

    const handleSubmit = async (data) => {
        const updatedTexts = [
            numOfTexts >= 1 && (data.get('text1') || text1),
            numOfTexts >= 2 && (data.get('text2') || text2),
            numOfTexts >= 3 && (data.get('text3') || text3),
            numOfTexts >= 4 && (data.get('text4') || text4),
            numOfTexts >= 5 && (data.get('text5') || text5),
            numOfTexts >= 6 && (data.get('text6') || text6),
            numOfTexts >= 7 && (data.get('text7') || text7),
            numOfTexts >= 8 && (data.get('text8') || text8),
            numOfTexts >= 9 && (data.get('text9') || text9),
            numOfTexts >= 10 && (data.get('text10') || text10),
        ].filter((v) => v && v !== '');

        const parsedLinks = [];
        const splitted = (data.get('links') || links).split(/(;|\n)/).filter((v) => v && v !== '');
        for (let index = 0; index < splitted.length; index++) {
            const values = splitted[index].split(',');
            const key = values[0];
            const text = values[1];
            const url = values[2];

            if(key && text && url) {
                parsedLinks.push({
                    key, text, url
                });
            }
        }

        const parsedPages = (data.get('facebookPages') || facebookPages).split(',').map((v) => v.trim());

        const input = {
            id: data.get('id') || id,
            name: data.get('name') || name,
            texts: updatedTexts.filter(Boolean),
            links: parsedLinks,
            mainLink: data.get('mainLink') || mainLink,
            facebookPages: parsedPages.filter(Boolean),
            postInterval: data.get('postInterval') || postInterval,
            hourFrom: data.get('hourFrom') !== null ? data.get('hourFrom') : hourFrom,
            hourTo: data.get('hourTo') !== null ? data.get('hourTo') : hourTo,
            allFacebookPages: data.get('allFacebookPages') !== null ? data.get('allFacebookPages') : allFacebookPages,
            active: data.get('active') !== null ? data.get('active') : active,
        };

        setLoading(true);
        const result = await apiClient.post('/api/r6/auto_posts' + (props.create ? '' : '/' + input.id), { data: input });

        if(result.status === 'ERROR') {
            setError(props.create ? 'Error on create' : 'Error on update');
            setLoading(false);
            return;
        }
        setError(null);
        setID(result.result.id);
        setName(result.result.name);
        setFacebookPages(result.result.facebookPages.join(','));
        setLinks(result.result.links.map((v) => `${v.key},${v.text},${v.url};`).join('\n'));
        setMainLink(result.result.mainLink);
        setAllFacebookPages(result.result.allFacebookPages);
        setPostInterval(result.result.postInterval);
        setHourFrom(result.result.hourFrom !== null ? result.result.hourFrom : '');
        setHourTo(result.result.hourTo !== null ? result.result.hourTo : '');
        setTexts(result.result.texts);
        setText1(result.result.texts[0] || '');
        setText2(result.result.texts[1] || '');
        setText3(result.result.texts[2] || '');
        setText4(result.result.texts[3] || '');
        setText5(result.result.texts[4] || '');
        setText6(result.result.texts[5] || '');
        setText7(result.result.texts[6] || '');
        setText8(result.result.texts[7] || '');
        setText9(result.result.texts[8] || '');
        setText10(result.result.texts[9] || '');
        setActive(result.result.active);
        setAudits(result.result.audits || []);
        setNumOfTexts(result.result.texts.length || 1);

        setLoading(false);
        if(props.create) {
            props.onSubmit();
        }
    };

  return (
    <div style={{ padding: '20px', display: 'flex', justifyContent: 'space-evenly' }}>
        <Paper style={{ width: '100%' }}>
            <div style={{ margin: '20px'}}>
                <Title
                    addPadding
                    text={props.create ? 'New post' : 'Details'}
                    variant="h6"
                    component="p"
                />
                {error ? <p>{error}</p> : null}
                {loading ?  <p>Loading...</p> : 
            ( <Form onSubmit={handleSubmit} submitTxt={props.create ? 'Create post' : 'Update post'} 
                    moreButtons={[
                        { label: 'Add Text', onClick: (data) => { 
                            if(numOfTexts < 10) { 
                                setNumOfTexts(numOfTexts+1); 
                            }
                        }}, 
                        { label: 'Remove Text', onClick: () => { 
                            if(numOfTexts > 0) { 
                                setNumOfTexts(numOfTexts-1); 
                            }
                        }}, 
                        props.create || !texts.length ? null : { label: 'Generate TEXT', onClick: () => { generateText(); }},
                        props.create || !texts.length ? null : { label: 'Generate HTML', onClick: () => { generateHtml(); }}
                    ].filter(Boolean)}>
                    <FormInput
                        key={'id'}
                        name={'id'}
                        label={'ID'}
                        defaultValue={id}
                        type={'text'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'name'}
                        name={'name'}
                        label={'Name'}
                        defaultValue={name}
                        type={'text'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'postInterval'}
                        name={'postInterval'}
                        label={'Post Interval'}
                        defaultValue={postInterval}
                        type={'number'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'hourFrom'}
                        name={'hourFrom'}
                        label={'Hour from'}
                        defaultValue={hourFrom}
                        type={'number'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'hourTo'}
                        name={'hourTo'}
                        label={'Hour to'}
                        defaultValue={hourTo}
                        type={'number'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'allFacebookPages'}
                        name={'allFacebookPages'}
                        label={'All FB Pages'}
                        defaultChecked={allFacebookPages}
                        type={'checkbox'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'active'}
                        name={'active'}
                        label={'Active'}
                        defaultChecked={active}
                        type={'checkbox'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'facebookPages'}
                        name={'facebookPages'}
                        label={'Facebook Pages'}
                        defaultValue={facebookPages}
                        type={'textarea'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <FormInput
                        key={'mainLink'}
                        name={'mainLink'}
                        label={'Main Link'}
                        defaultValue={mainLink}
                        type={'text'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px'}}><strong>Links {'(format: key,text,url;)'}</strong></div>
                    <FormInput
                        key={'links'}
                        name={'links'}
                        label={'Links'}
                        defaultValue={links}
                        rows={props.data?.links?.length}
                        type={'textarea'}
                        labelSm={3}
                        valueSm={8}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px'}}><strong>Texts {'(max: 10)'}</strong></div>
                    {numOfTexts >= 1 ? <FormInput
                            key={'text' + 1}
                            name={'text' + 1}
                            label={'Text ' + 1}
                            defaultValue={text1}
                            rows={text1?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 2 ? <FormInput
                            key={'text' + 2}
                            name={'text' + 2}
                            label={'Text ' + 2}
                            defaultValue={text2}
                            rows={text2?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 3 ? <FormInput
                            key={'text' + 3}
                            name={'text' + 3}
                            label={'Text ' + 3}
                            defaultValue={text3}
                            rows={text3?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 4 ? <FormInput
                            key={'text' + 4}
                            name={'text' + 4}
                            label={'Text ' + 4}
                            defaultValue={text4}
                            rows={text4?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 5 ? <FormInput
                            key={'text' + 5}
                            name={'text' + 5}
                            label={'Text ' + 5}
                            defaultValue={text5}
                            rows={text5?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                        {numOfTexts >= 6 ? <FormInput
                            key={'text' + 6}
                            name={'text' + 6}
                            label={'Text ' + 6}
                            defaultValue={text6}
                            rows={text6?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 7 ? <FormInput
                            key={'text' + 7}
                            name={'text' + 7}
                            label={'Text ' + 7}
                            defaultValue={text7}
                            rows={text7?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 8 ? <FormInput
                            key={'text' + 8}
                            name={'text' + 8}
                            label={'Text ' + 8}
                            defaultValue={text8}
                            rows={text8?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 9 ? <FormInput
                            key={'text' + 9}
                            name={'text' + 9}
                            label={'Text ' + 9}
                            defaultValue={text9}
                            rows={text9?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                    {numOfTexts >= 10 ? <FormInput
                            key={'text' + 10}
                            name={'text' + 10}
                            label={'Text ' + 10}
                            defaultValue={text10}
                            rows={text10?.split('\n').length}
                            type={'textarea'}
                            labelSm={3}
                            valueSm={8}
                        /> : null}
                </Form>) }
            </div>
        </Paper>
        {props.create ? null : <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Table
                title={'Facebook Posts'}
                columns={postColumn}
                rows={(audits?.filter((v) => v.type === 'fbpost') || []).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())}
                divStyling={{ margin: '20px' }}
                paperStyling={{ width: '100%' }}
                scrollable
            />
            <Table
                title={'Audits'}
                columns={auditColumn}
                rows={(audits?.filter((v) => v.type !== 'fbpost') || []).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())}
                detailOnClick={(row) => <AuditDetail data={row} span={auditColumn.length}/> }
                divStyling={{ margin: '20px' }}
                paperStyling={{ width: '100%' }}
                scrollable
            />
            {generatedText ? <Paper style={{ width: '100%', height: '100%' }}>
                <div style={{ margin: '20px'}}>
                    <Title
                        addPadding
                        text={'Generated Example'}
                        variant="h6"
                        component="p"
                    />
                    {HtmlContent({ htmlString: generatedText.replace(/\n/gm,'<br/>') })}
                </div>
            </Paper> : null}
        </div>}
    </div>
  );
};
