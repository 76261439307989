import { fromJS } from 'immutable';

import './notifications';

import * as actions from './actions';

// export * from './actions';

const ENDPOINT = '/api/boligninja';

const initialState = {
  user: null,
  userTransactions: null,
  userSubscriptions: null,
  users: [],
  transactions: [],
  transaction: null,
  subscriptions: [],
  subscription: null,
  userBoligagents: null,
  unsubscriptions: fromJS([])
};

export default function boligninja(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.GET_UNSUBSCRIPTIONS_SUCCESS: {
      return { ...state, unsubscriptions: fromJS(payload) };
    }

    case actions.GET_USER_BOLIGAGENTS_INIT:
    case actions.GET_USER_BOLIGAGENTS_SUCCESS:
    case actions.BOLIGAGENT_CHANGE_SUCCESS:
    case actions.BOLIGAGENT_STATUS_CHANGE_SUCCESS:
    case actions.BOLIGAGENT_PREF_CHANGE_SUCCESS: {
      return { ...state, userBoligagents: payload };
    }
  }

  if (type === actions.GET_USER_INIT) {
    return { ...state, user: null };
  }
  if (type === actions.GET_USER_SUBSCRIPTIONS_INIT) {
    return { ...state, userSubscriptions: null };
  }
  if (type === actions.GET_USER_TRANSACTIONS_INIT) {
    return { ...state, userTransactions: null };
  }

  if (type === actions.GET_USERS_SUCCESS) {
    return { ...state, users: payload };
  }
  if (
    type === actions.GET_USER_SUCCESS ||
    type === actions.UPDATE_USER_SUCCESS ||
    type === actions.DELETE_USER_SUCCESS
  ) {
    const user =
      !payload.audits &&
      state.user &&
      state.user.audits &&
      state.user._id &&
      payload._id
        ? { ...payload, audits: state.user.audits }
        : payload;

    return { ...state, user };
  }
  if (type === actions.GET_USER_SUBSCRIPTIONS_SUCCESS) {
    return { ...state, userSubscriptions: payload };
  }
  if (type === actions.GET_USER_TRANSACTIONS_SUCCESS) {
    return { ...state, userTransactions: payload };
  }
  if (type === actions.CANCEL_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      userSubscriptions:
        state.userSubscriptions && mergeById(state.userSubscriptions, payload),
      subscription: state.subscription && payload
    };
  }
  if (type === actions.GET_TRANSACTIONS_SUCCESS) {
    return { ...state, transactions: payload.transactions || [] };
  }
  if (type === actions.GET_TRANSACTION_SUCCESS) {
    return { ...state, transaction: payload.transaction };
  }
  if (type === actions.GET_SUBSCRIPTIONS_SUCCESS) {
    return { ...state, subscriptions: payload.subscriptions || [] };
  }
  if (type === actions.GET_SUBSCRIPTION_SUCCESS) {
    return { ...state, subscription: payload.subscription };
  }

  return state;
}

function mergeById(arr, obj) {
  return arr.map((item) => {
    return item._id === obj._id ? obj : item;
  });
}

function validateResponse(res) {
  if (res.status === 'OK') {
    return res.result;
  }
  throw new Error('Failed');
}

export function getUsers() {
  return {
    types: [
      actions.GET_USERS_INIT,
      actions.GET_USERS_SUCCESS,
      actions.GET_USERS_FAIL
    ],
    promise: (client) =>
      client.get(`${ENDPOINT}/users`).then((body) => body.results)
  };
}

export function getUser(userId) {
  return {
    types: [
      actions.GET_USER_INIT,
      actions.GET_USER_SUCCESS,
      actions.GET_USER_FAIL
    ],
    promise: (client) =>
      client
        .get(`${ENDPOINT}/users/${userId}?audit`)
        .then((body) => body.result)
  };
}

export function deleteUser(userId) {
  return {
    types: [
      actions.DELETE_USER_INIT,
      actions.DELETE_USER_SUCCESS,
      actions.DELETE_USER_FAIL
    ],
    promise: (client) =>
      client.delete(`${ENDPOINT}/users/${userId}`).then((body) => body.result)
  };
}

export function getUserSubscriptions(userId) {
  return {
    types: [
      actions.GET_USER_SUBSCRIPTIONS_INIT,
      actions.GET_USER_SUBSCRIPTIONS_SUCCESS,
      actions.GET_USER_SUBSCRIPTIONS_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/users/${userId}/subscriptions`)
  };
}

export function getUserBoligagents(userId) {
  return {
    types: [
      actions.GET_USER_BOLIGAGENTS_INIT,
      actions.GET_USER_BOLIGAGENTS_SUCCESS,
      actions.GET_USER_BOLIGAGENTS_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/users/${userId}/boligagents`)
  };
}

export function getUserTransactions(userId) {
  return {
    types: [
      actions.GET_USER_TRANSACTIONS_INIT,
      actions.GET_USER_TRANSACTIONS_SUCCESS,
      actions.GET_USER_TRANSACTIONS_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/users/${userId}/transactions`)
  };
}

export function getTransactions() {
  return {
    types: [
      actions.GET_TRANSACTIONS_INIT,
      actions.GET_TRANSACTIONS_SUCCESS,
      actions.GET_TRANSACTIONS_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/transactions`)
  };
}

export function getTransaction(transId) {
  return {
    types: [
      actions.GET_TRANSACTION_INIT,
      actions.GET_TRANSACTION_SUCCESS,
      actions.GET_TRANSACTION_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/transactions/${transId}`)
  };
}

export function getUserForTransaction(transId) {
  return {
    types: [
      actions.GET_USER_INIT,
      actions.GET_USER_SUCCESS,
      actions.GET_USER_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/transactions/${transId}/user`)
  };
}

export function getSubscriptions() {
  return {
    types: [
      actions.GET_SUBSCRIPTIONS_INIT,
      actions.GET_SUBSCRIPTIONS_SUCCESS,
      actions.GET_SUBSCRIPTIONS_FAIL
    ],
    promise: (client) => client.get(`${ENDPOINT}/legacy-subscriptions`)
  };
}

export function getSubscription(subsId) {
  return {
    types: [
      actions.GET_SUBSCRIPTION_INIT,
      actions.GET_SUBSCRIPTION_SUCCESS,
      actions.GET_SUBSCRIPTION_FAIL
    ],
    promise: (client) =>
      client.get(`${ENDPOINT}/legacy-subscriptions/${subsId}`)
  };
}

export function updateUser(data, id) {
  return {
    types: [
      actions.UPDATE_USER_INIT,
      actions.UPDATE_USER_SUCCESS,
      actions.UPDATE_USER_FALSE
    ],
    promise: (client) =>
      client.post(`${ENDPOINT}/users/${id}`, { data }).then(validateResponse)
  };
}

export function cancelSubscription(id) {
  return {
    types: [
      actions.CANCEL_SUBSCRIPTION_INIT,
      actions.CANCEL_SUBSCRIPTION_SUCCESS,
      actions.CANCEL_SUBSCRIPTION_FALSE
    ],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/subscriptions/${id}`, { data: { renew: false } })
        .then(validateResponse)
  };
}

export function changeBoligAgent(id, data) {
  return {
    types: [
      actions.BOLIGAGENT_CHANGE_INIT,
      actions.BOLIGAGENT_CHANGE_SUCCESS,
      actions.BOLIGAGENT_CHANGE_FAIL
    ],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/boligagents/${id}`, { data })
        .then(validateResponse)
  };
}

export function changeBoligAgentPref(id, prefId, data) {
  return {
    types: [
      actions.BOLIGAGENT_PREF_CHANGE_INIT,
      actions.BOLIGAGENT_PREF_CHANGE_SUCCESS,
      actions.BOLIGAGENT_PREF_CHANGE_FAIL
    ],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/boligagents/${id}/prefs/${prefId}`, { data })
        .then(validateResponse)
  };
}

export function changeBoligAgentStatus(id, data) {
  return {
    types: [
      actions.BOLIGAGENT_STATUS_CHANGE_INIT,
      actions.BOLIGAGENT_STATUS_CHANGE_SUCCESS,
      actions.BOLIGAGENT_STATUS_CHANGE_FAIL
    ],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/boligagents/${id}/status`, { data: {} })
        .then(validateResponse)
  };
}

export function getUnsubscriptions() {
  return {
    types: [
      actions.GET_UNSUBSCRIPTIONS_INIT,
      actions.GET_UNSUBSCRIPTIONS_SUCCESS,
      actions.GET_UNSUBSCRIPTIONS_FAIL
    ],
    promise: (client) =>
      client.get(`${ENDPOINT}/unsubscriptions`).then((data) => data.results)
  };
}