import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import { PlanDetail } from './planDetail';

type Props = {
  data: {
      id: string,
      name: string,
      currency:string,
      period: string,
      recurring: number,
      signup: number,
      trialPeriod: string,
      oneOff: number,
      description: string,
      paymentMethods: string[]
      active: boolean
  }
};

export default class PlanDetailRow extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    // try {
    //   if(!this.props.id) {
    //       return;
    //   }
    //   this.setState({ report: null, loading: true });
    //   const result = await apiClient.post('/api/microservice', { data: { action: 'PlanDetail', data: { id: this.props.id } } });
    //   this.setState({ result, loading: false });
    // } catch (err) {
    //   if (__DEV__) {
    //     console.error(err);
    //   }
    // }
  }

  render() {
    const { loading, result } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (<tr>
      <td colSpan="12">
        <PlanDetail data={this.props.data}/>
      </td>
    </tr>);
  }
}