import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import PlanDetailRow from './planDetailRow';
import { PlanDetail } from './planDetail';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const columns = [
  { label: 'ID', name: 'id', valueType: 'string' },
  { label: 'Name', name: 'name', valueType: 'string' },
  { label: 'Currency', name: 'currency', valueType: 'string' },
  { label: 'Period', name: 'period', valueType: 'string' },
  { label: 'Recurring', name: 'recurring', valueType: 'number' },
  { label: 'Signup', name: 'signup', valueType: 'number' },
  { label: 'Trial Period', name: 'trialPeriod', valueType: 'string' },
  { label: 'One-Off', name: 'oneOff', valueType: 'number' },
  { label: 'Description', name: 'description', valueType: 'string' },
  { label: 'Payment Methods', name: 'paymentMethods', valueType: 'stringify' },
  { label: 'Active Subscriptions', name: 'stats', valueType: 'custom', customValue: (value) => value?.find((v) => v.name === 'Active Subscriptions')?.value },
  { label: 'Active', name: 'active', valueType: 'boolean' }
];

export const PlanDashboard = (props: Props): JSX.Element => {
    const [list, setList] = React.useState([]);

    const [currency, setCurrency] = React.useState('DKK');
    const [active, setActive] = React.useState('true');

    const [loading, setLoading] = React.useState(false);
    const [create, setCreate] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        const filters = {
            currency,
            ...(active === 'all' ? {} : { active: active === 'true' })
        };
        apiClient.post('/api/microservice', { data: { action: 'GetPlans', data: { filters } } }).then((data) => {
          if(data?.data) {
            setList(data?.data);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          {/** RIGHT BUTTONS */}
          <div> 
            <span style={{ margin: '10px' }}>Active: </span>
            <Select style={{ margin: '10px' }} value={active} onChange={(ev) => setActive(ev.target.value)}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="false">False</MenuItem>
              <MenuItem value="true">True</MenuItem>
            </Select>
            <span style={{ margin: '10px' }}>Currency: </span>
            <Select style={{ margin: '10px' }} value={currency} onChange={(ev) => setCurrency(ev.target.value)}>
              <MenuItem value="DKK">DKK</MenuItem>
            </Select>
            <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
          </div>
          {/** LEFT BUTTONS */}
          <div>
            <Button style={{ margin: '6px' }} size={'small'} label={create ? 'Cancel' : 'Create new plan'} onClick={() => setCreate(!create)}/>
          </div>
        </div>
        { create ? <PlanDetail create onSubmit={() => { setCreate(false); refresh(); } }></PlanDetail>: null}
        { loading ? <p>Loading...</p> : !list?.length ? <p>No plans</p> : ( <Table
            title={`Plans (${list.length})`}
            columns={columns}
            rows={list || []}
            detailOnClick={(row) => <PlanDetailRow data={row}/> }
        />)}
    </div>
  );
};
