import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ButtonInput } from 'react-bootstrap';
import classNames from 'classnames';

class Form extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    submitTxt: PropTypes.string,
    updated: PropTypes.bool,
    noButton: PropTypes.bool,
    alignLeft: PropTypes.bool,
    moreButtons: PropTypes.arrayOf(PropTypes.any)
  };

  constructor() {
    super();
    this.data = new Map();
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.onSubmit(this.data);
  };

  handleChange = (ev) => {
    const target = ev.target;
    const type = target.getAttribute('type');
    const key = target.getAttribute('name');
    const value = type === 'checkbox' ? target.checked : target.value;
    this.data.set(key, value);
    if(this.props.onChange) {
      this.props.onChange(key, value);
    }
  };

  render() {
    const validFormComponents = ['FormInput', 'FormCellphone'];
    const children = React.Children.map(this.props.children, (child) => {
      if(!child) {
        return child;
      }
      if (validFormComponents.indexOf(child.type.displayName) != -1) {
        return React.cloneElement(child, { onChange: this.handleChange });
      } else {
        return child;
      }
    });

    const { submitTxt, updated, noButton, alignLeft, moreButtons, ...props } = this.props;
    const formClass = alignLeft ? '' : classNames('form-horizontal', this.props.className);

    return (
      <div>
        <form {...props} className={formClass} onSubmit={this.handleSubmit}>
          {children}

          {noButton ? null : <div className="row form-group">
            <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
              <button type="submit" className="btn btn-primary">
                {submitTxt || 'Save'}
              </button>
              {updated ?  <p>Updated!</p>: null}
            </div>
          </div>}
        </form>
        <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
        {moreButtons ? moreButtons.map((v, idx) => {
                return (
                  <button className="btn btn-primary" onClick={() => v.onClick(this.data)}>
                    {v.label || 'Button' + idx + 1}
                  </button>
                );
              }) : null}
        </div>
      </div>
    );
  }
}

export default Form;
