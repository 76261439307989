export default [
  {
    key: 'biz',
    label: 'Business',
    children: [
      {
        key: 'roster',
        label: 'Roster',
        to: '/biz/roster',
        icon: 'calendar'
      },
      {
        key: 'linktool',
        label: 'Link Tool',
        to: '/biz/linktool',
        icon: 'link'
      },
      {
        key: 'mastertool',
        label: 'Master Tool',
        to: '/biz/mastertool',
        icon: 'briefcase'
      },
      {
        key: 'suggesttool',
        label: 'Suggest Tool',
        to: '/biz/suggesttool',
        icon: 'briefcase'
      },
      {
        key: 'statistics',
        label: 'Landlord statistics',
        to: '/biz/statistics',
        icon: 'briefcase'
      },
      {
        key: 'disabletargettool',
        label: 'Disable target tool',
        to: '/biz/disabletargettool',
        icon: 'briefcase'
      }
    ]
  },
  {
    key: 'dk',
    label: 'DK',
    children: [
      { key: 'scrapers', label: 'Scrapers', to: '/scrapers', icon: 'download' },
      null,
      {
        key: 'reviewer',
        label: 'Reviewer',
        to: '/dk/reviewer',
        icon: 'binoculars'
      },
      null,
      {
        key: 'agents',
        label: 'Agents / Landlords',
        to: '/dk/agents',
        icon: 'group'
      },
      {
        key: 'dwellings',
        label: 'Dwellings',
        to: '/dk/dwellings',
        icon: 'home'
      },
      {
        key: 'leaseReport',
        label: 'Lease Report',
        to: '/dk/leaseReport',
        icon: 'file-text-o'
      },
      { key: 'reports', to: '/dk/reports', label: 'Reports', icon: 'bug' },
      null,
      { key: 'areas', label: 'Areas', icon: 'globe' },
      { key: 'geographies', to: '/dk/geographies', label: 'Geographies', icon: 'globe' },
      { key: 'images', to: '/dk/images', label: 'Images', icon: 'picture-o' },
      null,
      {
        key: 'report_dwellings_latest_fb_posts',
        label: 'Facebook Posts (first primary)',
        to: '/dk/data/latest_dwellings_fb_posts',
        icon: 'facebook'
      }
    ]
  },
  {
    key: 'r6',
    label: 'R6',
    children: [
      { key: 'stats', label: 'Stats', icon: 'line-chart' },
      { key: 'pages', label: 'Pages', to: '/r6/pages', icon: 'list' },
      { key: 'posts', label: 'Posts', icon: 'link' },
      {
        key: 'auto_posts',
        label: 'Auto posts',
        to: '/r6/auto_posts',
        icon: 'share'
      },
      {
        key: 'user_posts',
        label: 'User posts',
        to: '/r6/user_posts',
        icon: 'link'
      },
      {
        key: 'group_posting',
        label: 'Group posting',
        icon: 'link',
        to: '/r6/group_posting'
      },
      { key: 'engagement', label: 'Engagement', icon: 'comments-o' },
      null,
      { key: 'stats_generation', label: 'Generate stats', icon: 'refresh' },
      null,
      {
        key: 'report_r6_post_fans_ratio',
        label: 'Post to fans ratio',
        to: '/reports/r6PostsToFansRatio',
        icon: 'refresh'
      }
    ]
  },
  {
    key: 'akutbolig',
    label: 'Akutbolig',
    children: [
      { key: 'users', label: 'Users', to: '/akutbolig/users', icon: 'users' },
      {
        key: 'subscriptions',
        label: 'Subscriptions',
        to: '/akutbolig/subscriptions',
        icon: 'refresh'
      },
      {
        key: 'subscription-stats',
        label: 'Subscription Stats',
        to: '/akutbolig/subscription-stats',
        icon: 'file-text-o'
      },
      {
        key: 'subscription-ltv-report',
        label: 'Subscription LTV Report',
        to: '/akutbolig/subscription-ltv-report',
        icon: 'file-text-o'
      },
      {
        key: 'transactions',
        label: 'Transactions',
        to: '/akutbolig/transactions',
        icon: 'credit-card'
      },
      {
        key: 'scraperChats',
        label: 'Scraper Chats',
        to: '/akutbolig/scraperChats',
        icon: 'envelope'
      },
      {
        key: 'scraperChatTriggers',
        label: 'Scraper Chat Triggers',
        to: '/akutbolig/scraperChatTriggers',
        icon: 'bell'
      }
    ]
  },
  {
    key: 'boligbesked',
    label: 'Boligbesked',
    children: [
      { key: 'users', label: 'Users', to: '/boligbesked/users', icon: 'users' }
    ]
  },
  {
    key: 'boligninja',
    label: 'Boligninja',
    children: [
      { key: 'users', label: 'Users', to: '/boligninja/users', icon: 'users' },
      null,
      {
        key: 'subscriptions',
        label: 'Subscriptions',
        to: '/boligninja/subscriptions',
        icon: 'refresh'
      },
      {
        key: 'unsubscriptions',
        label: 'Unsubscriptions',
        to: '/boligninja/unsubscriptions',
        icon: 'times'
      },
      {
        key: 'transactions',
        label: 'Payment transactions',
        to: '/boligninja/transactions',
        icon: 'credit-card'
      }
    ]
  },
  {
    key: 'sparpenge',
    label: 'SparPenge',
    props: { href: 'http://sp.bbintern.net', target: '_blank' }
  },
  {
    key: 'microservices',
    label: 'MicroServices',
    children: [
      {
        key: 'actionqueue',
        label: 'Action Queue',
        to: '/microservices/actionqueue',
        icon: 'list'
      },
       {
        key: 'mailer',
        label: 'Mailer',
        to: '/microservices/mailer',
        icon: 'envelope'
      },
       {
        key: 'payment',
        label: 'Payment',
        to: '/microservices/payment',
        icon: 'credit-card'
      },
       {
        key: 'subscriptions',
        label: 'Susbcriptions',
        to: '/microservices/subscriptions',
        icon: 'credit-card'
      },
      {
       key: 'plans',
       label: 'Plans',
       to: '/microservices/plans',
       icon: 'credit-card'
     },
       {
        key: 'cron',
        label: 'Cron',
        to: '/microservices/cron',
        icon: 'repeat'
      },
       {
        key: 'chat',
        label: 'Chat',
        to: '/microservices/chat',
        icon: 'pencil'
      }
    ]
  },
  {
    key: 'reports',
    label: 'Reports',
    to: '/reports/',
    icon: 'file-text-o'
  }
];
