import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel, Table } from 'react-bootstrap';

import { formatDateStr } from '../user-helper';

export default class TrailPanel extends Component {
  static propTypes = {
    trail: PropTypes.arrayOf(
      PropTypes.shape({
        timestamp: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        message: PropTypes.string,
        remoteAddr: PropTypes.string
      })
    ).isRequired
  };

  render() {
    const { trail } = this.props;
    return (
      <Panel collapsible header={`Log / History (${trail.length})`}>
        <Table striped condensed responsive hover fill>
          <tbody>
            <tr>
              <th style={{ width: '40%' }}>Time</th>
              <th>Action</th>
              <th>IP</th>
            </tr>
            {trail.map((t, idx) => (
              <tr key={idx}>
                <td>{formatDateStr(t.timestamp)}</td>
                <td>
                  <span>
                    {t.action}{' '}
                    {t.status ? (
                      <>
                        ({t.status} {t.statusText && <i>- {t.statusText}</i>})
                      </>
                    ) : null}
                  </span>
                  {t.movedToStripe || t.movedFromPaylike? (
                    <span>
                      <br />
                      Subscription migrated from paylike to stripe
                    </span>
                  ) : null}
                  {t.message ? (
                    <span>
                      <br />
                      {t.message}
                    </span>
                  ) : null}
                </td>
                <td>
                  <i
                    className="fa fa-fw fa-globe"
                    style={{ cursor: 'pointer' }}
                  />{' '}
                  {t.remoteAddr}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    );
  }
}
