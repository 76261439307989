import React from 'react';
import { Table } from 'react-bootstrap';

type Props = {
    data: {
        old: any,
        new: any,
        update: any,
        timestamp: any
    }
};
export const AuditDetail = (props: Props): JSX.Element => {
  const [keys, setKeys] = React.useState(Object.keys(props.data.update || {}));
  return (
    <div style={{ padding: '20px' }}>
        <Table condensed responsive hover fill>
          <tbody>
            <tr>
              <th>KEY</th>
              <th>BEFORE</th>
              <th>AFTER</th>
            </tr>
            {keys.map((key, idx) => {
              const old = props.data.old[key];
              const newVal = props.data.new[key];
              
              return (
                <tr key={key+idx}>
                  <td style={{ width: '40%' }}>{key}</td>
                  <td>{JSON.stringify(old)}</td>
                  <td>{JSON.stringify(newVal)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
    </div>
  );
};
