import { Paper } from '@material-ui/core';
import React from 'react';
import { Table } from 'react-bootstrap';

type Props = {
    data: {
        update: any
    },
    span: number
};
export const AuditDetail = (props: Props): JSX.Element => {
  const [keys, setKeys] = React.useState(Object.keys(props.data.update || {}));
  return (
    (<tr>
      <td colSpan={props.span || '1'}>
        <Paper style={{ padding: '10px', marginTop: '10px' }}>
          <Table condensed responsive hover fill>
            <tbody>
              <tr>
                {keys.map((key) => {
                  return (
                    <th>
                      {key}
                    </th>
                  );
                })}
              </tr>
              <tr>
                {keys.map((key) => {
                  return (
                    <td>
                      {JSON.stringify(props.data.update[key])}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
      </Paper>
      </td>
    </tr>)
  );
};
