import React from 'react';
import apiClient from 'app/utils/api-client';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import DetailRow from './detailRow';
import { Detail } from './detail';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const columns = [
  { label: 'ID', name: 'id', valueType: 'string' },
  { label: 'Name', name: 'name', valueType: 'string' },
  { label: 'Texts', name: 'texts', valueType: 'custom', customValue: (value) => value?.length },
  { label: 'Links', name: 'links', valueType: 'custom', customValue: (value) => value?.length },
  { label: 'FB Pages', name: 'facebookPages', valueType: 'custom', customValue: (value) => value?.length },
  { label: 'Interval', name: 'postInterval', valueType: 'number' },
  { label: 'Hour From', name: 'hourFrom', valueType: 'number' },
  { label: 'Hour To', name: 'hourTo', valueType: 'number' },
  { label: 'All FB Pages', name: 'allFacebookPages', valueType: 'boolean' },
  { label: 'Active', name: 'active', valueType: 'boolean' }
];

export const AutoPostDashboard = (props: Props): JSX.Element => {
    const [list, setList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [create, setCreate] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        apiClient.get('/api/r6/auto_posts').then((data) => {
          if(data?.results) {
            setList(data?.results);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          {/** RIGHT BUTTONS */}
          <div> 
            <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
          </div>
          {/** LEFT BUTTONS */}
          <div>
            <Button style={{ margin: '6px' }} size={'small'} label={create ? 'Cancel' : 'Create new post'} onClick={() => setCreate(!create)}/>
          </div>
        </div>
        { create ? <Detail create onSubmit={() => { setCreate(false); refresh(); } }></Detail>: null}
        { loading ? <p>Loading...</p> : !list?.length ? <p>No auto posts</p> : ( <Table
            title={`Auto Posts (${list.length})`}
            columns={columns}
            rows={list || []}
            detailOnClick={(row) => <DetailRow data={row}/> }
        />)}
    </div>
  );
};
